
@font-face {
  font-family: "Suisse";
  src: url("/_Resources/Static/Packages/ModernPsy.Site/Fonts/SuisseIntl-Book-WebS.woff2") format("woff2");
}


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  #Pfad_191 {
    fill: none;
    stroke: #fff;
    stroke-width: 18.54px;

    /* Stroke-dasharray property */
    /*     stroke-dasharray: 50;
        stroke-dashoffset: 50; */
    /*     stroke-dashoffset: 500px; */
    /*     stroke-dasharray: 1353px;
        stroke-dashoffset: 1353px; */
    /*    animation: move 2s linear infinite;      */
    animation: move 1s linear;
    animation-fill-mode: forwards;
  }

  #Pfad_190 {
    fill: none;
    stroke: #fff;
    stroke-width: 18.54px;

    /* Stroke-dasharray property */
    /*     stroke-dasharray: 50;
        stroke-dashoffset: 50; */
    /*     stroke-dashoffset: 500px; */
    /*     stroke-dasharray: 1353px;
        stroke-dashoffset: 1353px; */
    /*    animation: move 2s linear infinite;      */
    animation: move 1s linear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  #Pfad_192 {
    fill: none;
    stroke: #fff;
    stroke-width: 18.54px;

    /* Stroke-dasharray property */
    /*     stroke-dasharray: 50;
        stroke-dashoffset: 50; */
    /*     stroke-dashoffset: 500px; */
    /*     stroke-dasharray: 1353px;
        stroke-dashoffset: 1353px; */
    /*    animation: move 2s linear infinite;      */
    animation: move 2s linear;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }

  @keyframes move {
    100% {
      stroke-dashoffset: 0;
    }
  }

  html, body {
    font-family: 'Suisse', sans-serif;
    overflow-x: clip;
  }

  nav {
    font-family: 'Suisse', sans-serif;
    font-weight: 800;
  }

  footer {
    box-shadow: 0 -10px 25px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    background-color: var(--footer-color);
  }

  p {
    margin-bottom: 0.5rem;
  }

  .changa {
    font-family: 'Changa One', sans-serif;
  }


  h1 {
    @apply text-[2rem];
  }

  h2 {
    @apply text-base;
  }

  .button {
    @apply rounded-full border border-[#397BE8] text-[#397BE8] px-4 py-1
  }

  #filter-icon path {
    stroke-width: 1;
  }

  .filter {
    @apply rounded-full border border-[#397BE8] text-[#397BE8] px-4 py-2 text-lg
  }

  .filter.selected {
    @apply text-white bg-[#397BE8] border-[#397BE8]
  }

  .button-white {
    @apply rounded-full border border-white text-white px-2 py-1
  }

  blockquote {
    font-size: 120%;
    @apply mb-2;
  }


  fieldset > div {
    @apply grid grid-cols-2 gap-8
  }

  .form-field-container {
    padding-bottom: 1.5rem;
  }

  fieldset .form-field-container  {
    input {
      border: 2px solid black;
      border-radius: 1.5rem;
      width: 100%;
    }
  }

  .form-field-container textarea {
    width: 100%;
    height: 10rem;
    border: 2px solid black;
    border-radius: 1.5rem;
    padding: 1.5rem;
  }

  .form-field-container button {
    color: white;
    background-color: #397BE8;
    border-radius: 2rem;
    padding: 1rem 2rem;
    float: right;
  }

  .headline {
    background: linear-gradient(
      to right,
      #e6007e 0%,
      #e6007e calc((100vw - 64rem)/2),
      transparent calc((100vw - 64rem)/2),
      transparent 100%
    );
  }

  .headline h1 {
    margin: 0;
    display: inline-block;
    height: 100%;
    background-color: #e6007e;
    padding: 0.05em 0.2em;
    color: white;
    text-transform: uppercase;

    @apply text-4xl md:text-5xl 2xl:text-6xl;

    @media (max-width: 1279px) {
      position: relative;
      padding-left: 4rem;
      left: 0;
    }

  }

  .dateline {
    background: linear-gradient(
      to right,
      #fff 0%,
      #fff calc((100vw - 64rem)/2),
      transparent calc((100vw - 64rem)/2),
      transparent 100%
    );
    date {
      margin: 0;
      display: inline-block;
      border-left: 1px #fff solid;
      font-family: 'Changa One', sans-serif;
      height: 100%;
      background-color: #fff;
      padding: 0.05em 0.5em;
      color: black;
      text-transform: uppercase;

      @apply text-3xl md:text-4xl ;

      @media (max-width: 1279px) {
        position: relative;
        padding-left: 4rem;
        left: 0;
      }
    }
  }

  img.fade {
    mask-image: linear-gradient(to right, transparent 0%, rgb(0, 0, 0) 5%, rgb(0, 0, 0) 95%, transparent 100%);
  }


  .logo {
    font-family: 'Changa One', sans-serif;
    color: black;
    display: flex;
    flex-direction: column;
    /*margin-bottom: 1rem;*/
    /*position: absolute;*/
    /*transform: rotate(-5deg);*/
    /*top: 1rem;*/

    span {
      padding-left: 3rem;
      left: -3rem;
      padding-right: 0.5rem;
      position: relative;
      width: max-content;
      line-height: 1;
    }

    .topline {
      color: black;
      background-color: white;
      font-weight: 300;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    .main {
      font-weight: 500;

      color: var(--logo-main-font-color);
      background-color: var(--logo-main-background-color);
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      padding-left: 2.6rem;
      padding-right: 1rem;
    }

    .subline {
      color: var(--logo-subline-font-color);
      background-color: var(--logo-subline-background-color);
      font-weight: 300;
      text-transform: uppercase;
      padding-left: 2.6rem;
    }


  }

  .k-icon {
    display: inline-block;
    @apply p-1 mr-2;
    svg {
      width: 2rem;
      height: 2rem;
    }

    &.instagram {
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)
    }

    &.website {
      background-color: #e6007e;
    }

  }

  .content-text ul {
    list-style: disc;
    padding-left: 2rem;
  }

  .neos-contentcollection form {
    @apply mx-8 md:mx-16 lg:mx-auto lg:max-w-4xl text-lg;
    input, textarea {
      width: 100%;
      border: 1px solid black;
      padding: 0.5rem;
    }
    label {
      margin-top: 1rem;
      display: block;
    }
    .submit {
      text-align: right;
      button {
        padding: 0.5rem 1rem;
        background-color: #e6007e;
        color: white;
      }
    }

  }

  a.external-link svg {
    display: inline;
    margin-left: 0.5rem;
    bottom: 3px;
    position: relative;
  }

}


